<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="pageMain">
      <swiper :options="topPartyOptions">
            <swiper-slide v-for="(item,key) in topList" :key="key">
              <img  @click="gotoDetail(item)" :src="`${$baseUrl}${item.thumb}`"/>
            </swiper-slide>
      </swiper>
      
      <div class="p20">
        <div class="partySwiper">  
          <swiper :options="partyOptions">
            <swiper-slide v-for="(item,key) in firstList" :key="key">
              <div class="list2 list2Block" v-for="(sub,key) in item" :key="key" @click="gotoDetail(sub)">
                <el-row :gutter="10">
                  <el-col :span="9">
                    <a><img :src="`${$baseUrl}${sub.thumb}`"></a>
                  </el-col>
                  <el-col :span="15">
                    <h4>{{sub.title}}</h4>
                    <div class="list2Date">2020.06.04</div>
                    <div class="list2Text">
                      {{sub.description}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </swiper-slide>
          </swiper> 
          <div class="swiper-pagination"></div>
        </div>

      </div>

      <div class="partyTab">
        <el-row :gutter="10">
          <el-col :span="8">
            <span :class="tabIndex == 1 ? 'active': ''" @click="tabAction(1)"><img src="@/assets/i1.png"  width="17"/>党建概括</span>
          </el-col>
          <el-col :span="8">
            <span :class="tabIndex == 2 ? 'active': ''" @click="tabAction(2)"><img src="@/assets/i2.png"  width="17"/>党群活动</span>
          </el-col>
          <el-col :span="8">
            <span :class="tabIndex == 3 ? 'active': ''" @click="tabAction(3)"><img src="@/assets/i3.png"  width="17"/>信息发布</span>
          </el-col>
        </el-row>
      </div>

      <div class="partyTabMain p20">
        <div class="list2" v-for="(item,key) in dNewsList" :key="key"  @click="gotoDetail(item)">
          <el-row :gutter="10">
            <el-col :span="9">
            <a><img :src="`${$baseUrl}${item.thumb}`"></a></el-col>
            <el-col :span="15">
              <h4><a to="/party/1">{{item.title}}</a></h4>
              <div class="list2Date">2020.06.04</div>
              <div class="list2Text">
                {{item.description}}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- <div class="p20">
        <div class="paging">
          <span class="prev disable"><img src="@/assets/prev.png" width="6" /></span>
          <div class="digital">
            <span class="cut">1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
          </div>
          <span class="next"><img src="@/assets/next.png" width="6" /></span>
        </div>
      </div> -->

      <Footer />

      
    </div>
    
  </div>
</template>

<style scoped>
 
</style>
<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/footer.vue'
const typeList = {
  '0' : 'default' ,
  '1' : 'activity' ,
  '2' : 'release' 
}
export default {
  
  name: 'App',
  components: {
    Nav,
    Footer
  },
  data () {
    return {
      isIndex: false,
      tabIndex: 1,
      topList: [],
      firstList: [],
      dNewsList: [],
      newsPic:[
        {
          title:'b站',
          img:require('@/assets/p13.jpg')
        },{
          title:'c站',
          img:require('@/assets/p13.jpg')
        },{
          title:'e站',
          img:require('@/assets/p13.jpg')
        }
        
      ],
      topPartyOptions: {
          slidesPerView: 1, 
          spaceBetween: 30,
      },
      partyOptions: {
          slidesPerView: 1, 
          spaceBetween: 30,
          pagination:{
            el:'.partySwiper .swiper-pagination',
            clickable:true,
          }
        // Some Swiper option/callback...
      },
    }
  },
  computed: {
      
  },
  created() {
    this.getPartyList()
    this.getTopList()
    this.getFirstList()
  },
  methods:{
    tabAction(key) {
      if (key == this.tabIndex) return false;
      this.tabIndex = key
      this.getPartyList()
    },
    getPartyList() {
      //党建新闻类型 top:置顶 default:党建概况 activity:党群活动 release:信息发布
      let type = typeList[this.tabIndex]
      this.$ajax
        .get("/cms/api/dnewslist", {
          dtype : type
        })
        .then((res) => {
          if (res.code == "200") {
            this.dNewsList = res.data
            console.log(this.dNewsList);
          }
        });
    },
    getTopList() {
      this.$ajax
        .get("/cms/api/dnewslist", {
          dtype : 'top'
        })
        .then((res) => {
          if (res.code == "200") {
            this.topList = res.data
            console.log(this.topList);
          }
        });
    },

    // 获取头条
    getFirstList() {
      this.$ajax
        .get("/cms/api/dnewslist", {
          dtype : 'first'
        })
        .then((res) => {
          if (res.code == "200" && res.data.length) {
            let firstList = []
            let data =res.data
            for(let i=0; i<data.length; i+=2){
                firstList.push(data.slice(i,i+2));
            }
            this.firstList = firstList
            console.log(this.firstList)
          }
        });
    },

    gotoDetail(item){
      console.log('22', item)
      this.$router.push({ name: "PartyView", params: { id: item.id } });
    }
  },
}
</script>

